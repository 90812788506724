<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useGlobalStore } from '@/stores/global';
	import { useHeaderStore } from '@/stores/header';

	// Variables
	const { $bus } = useNuxtApp();
	const { generalInformation, menu: menuOptions } = storeToRefs(useGlobalStore());
	const { menu } = storeToRefs(useHeaderStore());

	const searchOpen = ref(false);
	const query = ref('');
	const { cta, bottomLink, iconLinks } = menuOptions.value;

	const { data: isLoggedIn, error } = await useAsyncData(
		'login-status', () => $fetch(`/api/tn/auth`)
	)

	// Methods
	const openSearch = () => {
		$bus.emit('scroll-lock', { search: true });
		searchOpen.value = true;
		setTimeout(() => {
			$bus.emit('open-search');
			query.value = '';
		}, 32);
	};

	const closeSearch = () => {
		$bus.emit('scroll-unlock');
		searchOpen.value = false;
	};

	const handleSearch = () => {
		openSearch();
	};

	const link = computed(() => {
		if (isLoggedIn?.value?.loggedIn) return 'https://cart.architecture.org/account';
		return 'https://cart.architecture.org/account/login' + '?returnUrl=' + window.location.href;
	});

</script>
<template>
	<div class="mobile-menu">
		<div class="container">
			<div class="column left">
				<div class="location">
					<h2>Location</h2>
					<h3 v-if="generalInformation.address" v-html="generalInformation.address" />
					<!-- <FragmentsHours /> -->
					<form @submit.prevent="handleSearch">
						<div class="input-wrapper">
							<input name="full-menu-search" id="full-menu-search" v-model="query" placeholder="Search" />
							<button @click.prevent="handleSearch">
								<span class="sr-only">Search</span>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
									<path
										d="M13.8333 7.35696C13.8333 10.8679 10.9871 13.7141 7.47616 13.7141C3.96521 13.7141 1.11902 10.8679 1.11902 7.35696C1.11902 3.84601 3.96521 0.999817 7.47616 0.999817C10.9871 0.999817 13.8333 3.84601 13.8333 7.35696Z"
										stroke="currentColor"
									/>
									<path d="M12.4286 11.548L17 16.5004" stroke="currentColor" stroke-linecap="round" />
								</svg>
							</button>
							<ProjectModal :opened="searchOpen" @close="closeSearch" :classes="'search-modal'">
								<GlobalAlgolia :initialQuery="query" />
							</ProjectModal>
						</div>
					</form>
					<ProjectButton class="login-logout" :link="link">
						<span class="label"> {{ isLoggedIn?.value?.loggedIn ? 'Logout' : 'Login' }} </span>
						<svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
							<path
								d="M7.36944 8.68189C9.62885 8.68189 11.4605 6.85028 11.4605 4.59087C11.4605 2.33146 9.62885 0.499847 7.36944 0.499847C5.11003 0.499847 3.27841 2.33146 3.27841 4.59087C3.27841 6.85028 5.11003 8.68189 7.36944 8.68189Z"
								fill="currentColor"
							/>
							<path
								d="M0.694308 18.5002H14.1138C14.4944 18.5002 14.803 18.1916 14.803 17.811V13.0692C14.803 11.5263 13.5532 10.2714 12.0052 10.2714H2.79779C1.25489 10.2714 0 11.5211 0 13.0692V17.811C0.005143 18.1916 0.313726 18.5002 0.694308 18.5002Z"
								fill="currentColor"
							/>
						</svg>
					</ProjectButton>
				</div>
			</div>
			<div class="column right">
				<nav>
					<ProjectNavAccordion :items="menu" toggleIcon="chevron-down">
						<template #item="item">
							<ul class="nav-sub-items">
								<li class="sub-label" v-if="item[0]?.mobileLabel">
									<MessLink :href="item[0]?.url">
										{{ item[0].mobileLabel }}
									</MessLink>
								</li>
								<li class="sub-label" v-for="subitem in item[0]?.subitems">
									<MessLink :href="subitem.url">
										{{ subitem.label }}
									</MessLink>
								</li>
							</ul>
						</template>
					</ProjectNavAccordion>

					<!-- <ul v-for="item in menu" class="nav-items">
						<li class="label">
							<MessLink :href="item.url">
								{{ item.label }}
							</MessLink>
						</li>
						<li class="sub-label" v-for="subitem in item.subitems">
							<MessLink :href="subitem.url">
								{{ subitem.label }}
							</MessLink>
						</li>
					</ul> -->
					<ul class="icons">
						<li v-for="icon in iconLinks">
							<MessLink :href="icon.link" class="link">
								<MessImage :src="icon.icon" />
								<!-- <ProjectIcon :icon="icon.icon" width="30" height="30" /> -->
								<span>{{ icon.label }}</span>
							</MessLink>
						</li>
					</ul>
				</nav>
				<footer class="footer-nav">
					<ul class="left">
						<li v-for="link in bottomLink.left">
							<MessLink :href="link.link">
								{{ link.label }}
							</MessLink>
						</li>
					</ul>
					<ul class="right">
						<li v-for="link in bottomLink.right">
							<MessLink :href="link.link">
								{{ link.label }}
							</MessLink>
						</li>
					</ul>
				</footer>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	.mobile-menu {
		display: flex;
		align-items: flex-start;
		min-height: 100%;
		padding-bottom: 3rem;

		.container {
			margin: 0 auto;
			margin-top: 3rem;

			.left {
				overflow: hidden;
				.location {
					background: white;
					padding: 2rem;
					h2 {
						color: var(--black, #000);
						font-size: 20px;
						font-style: normal;
						font-weight: 900;
						line-height: 156.1%; /* 31.22px */
						letter-spacing: 0.3px;
					}
					h3 {
						color: var(--black, #000);
						font-family: Avenir;
						font-size: 16px;
						font-style: normal;
						font-weight: 350;
						line-height: 156.1%; /* 24.976px */
						letter-spacing: 0.24px;
						text-transform: none;

						margin-top: 1.2rem;
					}

					.fragment-center-hours {
						margin-top: 1.25rem;
						.base-hours {
							gap: 0;
						}
						.special-closures {
							display: none;
						}
					}

					.input-wrapper {
						margin-top: 1.5rem;
						position: relative;

						input {
							width: 100%;
							border: 1px solid;
							font-size: 16px;
							font-style: normal;
							font-weight: 350;
							line-height: 156.1%; /* 24.976px */
							letter-spacing: 0.24px;
							padding: 0.75rem 1rem;
						}

						button {
							position: absolute;
							right: 1rem;
							top: 50%;
							transform: translate(0, -50%);
							height: 100%;
							display: flex;
							align-items: center;
							transition: 250ms ease-in-out;
							&:hover {
								background: transparent;
								color: var(--gray-600);
							}
						}
					}

					.login-logout {
						margin-top: 1.5rem;
						text-transform: uppercase;
						color: var(--red, #c00);
						/* Utility/Button Label */
						font-size: 12px;
						font-style: normal;
						font-weight: 900;
						line-height: 123.1%; /* 14.772px */
						letter-spacing: 1.38px;
						text-transform: uppercase;

						display: inline-flex;
						align-items: center;
						justify-content: center;

						.label {
							margin-right: 1em;
						}

						svg {
							width: 14px;
							height: 17px;
						}

						&:hover {
							color: white;
						}
					}
				}
				.cta {
					padding: 1.25rem;
					background: white;
					margin-top: 2rem;
					.content {
						margin: 1rem 0;

						.title {
							color: var(--black, #000);
							font-size: 20px;
							font-style: normal;
							font-weight: 900;
							line-height: 114.6%; /* 22.92px */
							letter-spacing: 0.3px;
							text-transform: uppercase;
						}

						.description {
							color: var(--black, #000);

							/* Body/Body 2 */
							font-size: 16px;
							font-style: normal;
							font-weight: 350;
							line-height: 156.1%; /* 24.976px */
							letter-spacing: 0.24px;
						}
					}
				}
			}

			.column.right {
				background: var(--gray-100);
				margin: 1.75rem 0 0 0;

				nav {
					display: flex;
					flex-direction: column;
					gap: 2rem;

					.nav-items {
						display: flex;
						flex-direction: column;

						.nav-sub-items {
							display: flex;
							flex-direction: column;
							gap: 1.25rem;
							margin-top: 1rem;
						}

						.label,
						.label a,
						.label span {
							color: var(--red, #c00);
							/* Headline/H4 */
							font-size: 26px;
							font-style: normal;
							font-weight: 900;
							line-height: 114.6%; /* 29.796px */
							letter-spacing: 0.39px;
							text-transform: uppercase;
							margin-bottom: 0.5rem;
							background-image: none !important;
						}

						.label a {
							transition: 250ms ease-in-out;

							&.mess-current-active-link,
							&.mess-active-link,
							&:hover {
								color: var(--black);
							}
						}

						.sub-label {
							color: var(--black);
							font-size: 17px;
							font-style: normal;
							font-weight: 600;
							line-height: 156.1%; /* 26.537px */
							letter-spacing: 0.255px;
						}

						a {
							transition: 250ms ease-in-out;
							background-image: linear-gradient(transparent, transparent);
							background-position: 0 100%;
							background-repeat: no-repeat;
							background-size: 0% 0px;
							box-decoration-break: clone;
							-webkit-box-decoration-break: clone;

							&:hover,
							&.mess-current-active-link,
							&.mess-active-link {
								background-image: linear-gradient(currentColor, currentColor);
								background-size: 100% 1px;
								color: var(--red);
								&::after {
									background: currentColor;
								}
							}
						}
					}

					.icons {
						display: flex;
						flex-direction: column;
						gap: 2rem;

						li {
							width: 100%;

							.link {
								display: flex;
								align-items: center;
								width: 100%;
								gap: 1rem;
								text-decoration: none;

								.cac-icon {
									width: 56px;
									height: 56px;
								}

								span {
									color: var(--red, #c00);
									/* Headline/H4 */
									font-size: 26px;
									font-style: normal;
									font-weight: 900;
									line-height: 114.6%; /* 29.796px */
									letter-spacing: 0.39px;
									text-transform: uppercase;
									text-decoration: none;
								}

								.mess-image {
									background: white;
									height: 56px;
									width: 56px;
									border-radius: 50%;
									position: relative;
									img {
										@include absolute-center;
										width: 30px;
										height: auto;
									}
								}
							}
						}
					}
				}

				footer {
					padding: 0;
					margin-top: 2.75rem;

					ul {
						padding: 0;
						width: 100%;

						&.right {
							li {
								&:first-of-type {
									margin-top: 0.5rem;
								}
							}
						}
						li {
							&:not(:first-of-type) {
								margin-top: 0.5rem;
							}
						}
					}
				}
			}
		}
	}
</style>
